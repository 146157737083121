<header class="header" ngClass.gt-sm="isDesktop" [subscription]="subscription"></header>

<sidenav *ngIf="isMobile" [hideSidenav]="closeSidenav">
</sidenav>

<sidenav-account *ngIf="isMobile" [isRecruiters]="this.env.GlobalVars.name === 'jobology'">
</sidenav-account>

<!-- <router-outlet name="layout-outlet"></router-outlet> -->

<div class="content" ngClass.gt-sm="isDesktop" (click)="dismissSidenav()">

  <router-outlet></router-outlet>

  <!--   <ng-container *ngIf="!env.production">
    <b>ENV</b> : {{ env?.name | json}}, <b>ID</b> : {{ env?.GlobalVars.brand | json }}
  </ng-container> -->
</div>

<!-- <footer class="footer" ngClass.gt-sm="isDesktop"></footer> -->

<!-- <div #cookiesBarre id="CookiesBarre" *ngIf="showCookiesBarre; cookieConsent" ngClass.gt-sm="isDesktop">
  <span>Ce site utilise des cookies pour vous offrir le meilleur service. En
    poursuivant votre
    navigation, vous acceptez l’utilisation des cookies.</span>
  <a href="/espace-recruteurs/declaration-de-confidentialite.aspx#EFAA" target="_blank" style="margin-left: 15px">En
    savoir plus</a>
  <a id="cookieChoiceDismiss" role="button" style="margin-left: 15px;text-decoration: underline; cursor: pointer;"
    (click)="removeCookiesBarre($event)">J’accepte</a>
</div> -->

<scrolltop></scrolltop>