import { Injectable, Output } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from './auth.service';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventEmitter } from 'protractor';

@Injectable()
export class HeaderService {
  toggleActive: boolean = false;
  showAccountMenu: boolean = false;

  private sidenav: MatSidenav;

  private isLoggedSubscription: Subscription = new Subscription();
  public isLogged;
  // public shortUsernameObs = new EventEmitter();

  shortUsername: string;

  // basket counter
  public basketCount: Subject<number> = new Subject<number>();

  // Detect current page to customize header
  public pagesList = {
    'post-a-job': {
      class: 'post-a-job-page',
      current: false
    },
    'resumes': {
      class: 'resumes-page',
      current: false
    },
    'checkout': {
      class: 'checkout-page',
      current: false
    },
    '404': {
      class: '404-page',
      current: false
    }
  };

  bannerIsActive: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {
    /*   this.isLoggedSubscription = this.authService.getIsAuthorized().pipe(take(1)).subscribe(
        (isAuthorized: boolean) => {
          this.isLogged = isAuthorized;
        }
      ); */

  }

  /** MatSidenav Mobile */
  sidenavOpenedChange$: Subject<boolean> = new Subject<boolean>();

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  public open() {
    this.toggleActive = true;
    return this.sidenav.open();
  }

  public close() {
    this.toggleActive = false;
    return this.sidenav.close();
  }

  public toggle(): void {
    this.toggleActive = !this.toggleActive;
    this.sidenav.toggle();
  }

  accountMenu: Subject<boolean> = new BehaviorSubject<boolean>(this.showAccountMenu)
  public toggleMyAccount() {
    this.sidenav.close();
    this.toggleActive = false;
    this.showAccountMenu = !this.showAccountMenu;
    this.accountMenu.next(this.showAccountMenu);
  }

  public openMyAccount() {
    this.sidenav.close();
    this.toggleActive = false;
    this.showAccountMenu = true;
    this.accountMenu.next(this.showAccountMenu);
  }


  public closeMyAccount() {
    this.sidenav.close();
    this.toggleActive = false;
    this.showAccountMenu = false;
    this.accountMenu.next(this.showAccountMenu);
  }

  public getShortname(username: string, lastname: string): any {
    this.shortUsername = username! && lastname! ? username[0] + lastname[0] : '';

    return this.shortUsername;
  }

  /** MatMenu (Desktop Header) **/

  enteredButton: boolean = false;
  isMatMenuOpen: boolean = false;
  isMatMenu2Open: boolean = false;
  prevButtonTrigger;

  public buttonEnter(trigger: any, renderer, el?) {

    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();

        renderer.removeClass(trigger['_element'].nativeElement, 'cdk-focused');
        renderer.removeClass(trigger['_element'].nativeElement, 'cdk-program-focused');
      }
      else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
        trigger.openMenu();

        renderer.removeClass(trigger['_element'].nativeElement, 'cdk-focused');
        renderer.removeClass(trigger['_element'].nativeElement, 'cdk-program-focused');
      }
      else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger
      }
    }, 80)
  }

  public buttonLeave(trigger: any, button: any, renderer?) {

    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        renderer.removeClass(button['_element'].nativeElement, 'cdk-focused');
        renderer.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      }

      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        renderer.removeClass(button['_element'].nativeElement, 'cdk-focused');
        renderer.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 50);
  }

  public menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  public menuLeave(trigger: any, button: any, renderer) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        renderer.removeClass(button['_element'].nativeElement, 'cdk-focused');
        renderer.removeClass(button['_element'].nativeElement, 'cdk-program-focused');
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80)
  }
}