import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserService } from 'src/app/core/services/user.service';
// import { PopinJobDepositComponent } from '../../popin/popin-jobDeposit/popin-jobDeposit.component';
import * as clone from 'clone';
import { PopinSubscriptionComponent } from '../../popin/popin-subscription/popin-subscription.component';
import { environment } from '@env';

@Component({
  selector: 'sidenav-account',
  templateUrl: './sidenav-account.component.html',
  styleUrls: ['./sidenav-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavAccountComponent implements OnInit {
  @ViewChild('accountSidenav', { static: true }) public sidenav: MatSidenav;
  @Input() shortName: string;
  @Input() isRecruiters: string;
  shortUsername: string;
  userDataSubscription: Subscription;
  username: string;

  constructor(public headerService: HeaderService, public authService: AuthMSALService,
    private popin: MatDialog, public userService: UserService, private dialog: MatDialog) {
  }

  tenantsList;
  tenantActive;
  tenantsList$;
  tenantActive$

  userDatas;
  authService$;

  ngOnInit() {
    this.getSubscription();
    this.getTenants();

    this.userService.getRecruteur(this.authService.getClaims('sub')).subscribe(recruteurResult => {

      if (recruteurResult) {
        let result = clone(recruteurResult);
        this.userDatas = result;
        this.avatarRecruteur = result.avatarBase64;
      }
    })

    // If user datas is updated
    this.authService$ = this.authService.userDatas.subscribe(result => {

      this.userDatas = result;
      this.avatarRecruteur = result.avatarBase64;

    })

    this.headerService.accountMenu.subscribe(show => {
      if (show) {
        return this.sidenav.open();
      } else {
        return this.sidenav.close();
      }
    });
  }

  displayExpiration;
  timeRemaining;
  subscription;
  getSubscription() {

    this.userService.shareSubscriptionDatas$.pipe(take(1)).subscribe(result => {
      if (result) {
        this.subscription = clone(result);

        // Count days remaining
        // let dateFin = this.subscription.dateFinTrial ? Date.parse(this.subscription.dateFinTrial) : Date.parse(this.subscription.dateFin)
        let dateFin = Date.parse(this.subscription.dateFin)
        let dateToday = Date.now();

        // Convert ms to days
        this.timeRemaining = Math.floor((dateFin - dateToday) / (24 * 60 * 60 * 1000));

        if (this.timeRemaining <= 15 && !this.subscription.renouvellementAuto) {
          this.displayExpiration = true;
        }
      }

    })


  }
  avatarRecruteur;
  ngAfterViewInit() {

    this.userService.checkActiveTenant();

    // this.authService.userDatas.pipe(take(1)).subscribe(result => {

    //   if (result.sub) {
    //     this.userService.getRecruteur(result.sub).pipe(take(1)).subscribe(recruteurResult => {
    //       if (recruteurResult) {
    //         let result = clone(recruteurResult);
    //         this.avatarRecruteur = result.avatarBase64;
    //         // this.avatarRecruteur = recruteurResult.data.recruteur.avatarBase64;
    //       }
    //     })
    //   }

    // })
    /*  setTimeout(() => {
       this.userDataSubscription = this.authService.getUserData().subscribe(userData => {
         if (userData) {
           this.username = `${userData.given_name} ${userData.family_name}`
           this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
           this.authService.handleRoles(userData);
         }
       });
 
       // If name is updated (my account)
       this.userService.userDataSub.subscribe(userData => {
         this.username = `${userData.given_name} ${userData.family_name}`
         this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
       });
     }); */
  }

  ngOnDestroy() {
    this.tenantsList$.unsubscribe();
    this.tenantActive$.unsubscribe();
    this.authService$.unsubscribe();
  }

  getTenants() {

    this.tenantsList$ = this.userService.memberTenantsList.subscribe(result => {
      if (Object.keys(result)?.length > 0) {
        this.tenantsList = result;
      }
    })

    this.tenantActive$ = this.userService.tenantActive.subscribe(result => {
      if (Object.keys(result)?.length > 0) {
        this.tenantActive = result;
      }
    })
  }


  setTenant(tenant) {
    //this.userService.setTenant(tenant);
    this.handleSubscription(tenant);
  }

  handleSubscription(tenant) {

    this.userService.getSubscription(tenant.id).pipe(take(1)).subscribe(result => {

      if (result) {
        this.subscription = result;

        // Count days remaining
        // let dateFin = this.subscription.dateFinTrial ? Date.parse(this.subscription.dateFinTrial) : Date.parse(this.subscription.dateFin)
        let dateFin = Date.parse(this.subscription.dateFin)
        let dateToday = Date.now();
        // Convert ms to days
        this.timeRemaining = Math.floor((dateFin - dateToday) / (24 * 60 * 60 * 1000));

        // Subscription ends
        if (this.subscription.estActif) {
          this.userService.listenSubscriptionOn$.next(false);
          (<any>localStorage).setItem('subscriptionOn', 'false')
          this.openPopinSubscription(this.subscription, tenant)
        } else {
          this.userService.listenSubscriptionOn$.next(true);
          (<any>localStorage).setItem('subscriptionOn', 'true')
          this.userService.setTenant(tenant, true);
        }
      } else {

        this.userService.listenSubscriptionOn$.next(false);
        (<any>localStorage).setItem('subscriptionOn', 'false')
        this.openPopinSubscription(null, tenant)
      }


    })
  }

  openPopinSubscription(tenantSub, tenant) {
    let popin = this.dialog.open(PopinSubscriptionComponent, {
      width: '100%',
      maxWidth: '700px',
      disableClose: false,
      autoFocus: false,
      panelClass: 'popin-subscription-panel',
      data: {
        tenantSub: tenantSub,
        tenant: tenant,
        title: `Renouvelez votre abonnement pour ${tenant.name}`,
        content: `<p>Votre abonnement a expiré, ce qui signifie que vous n'avez plus accès à l'application.</p>
        <p>Pour continuer à profiter pleinement de notre application et de ses fonctionnalités avancées, nous vous invitons à
          renouveler votre abonnement dès maintenant.</p>
        <p>Pour consulter nos tarifs d'abonnement et choisir celui qui vous convient le mieux, veuillez cliquer
          sur le lien ci-dessous :
        </p>`,
        displayBack: false,
        displayClose: true,
        linkCTA: `${environment.path.blog.mainUrl}/mon-compte/subscriptions/?tenantid=${this.tenantActive.id}`
      }
    })
  }

  open() {
    return this.sidenav.open();
  }

  closeAllNav() {
    this.sidenav.close();
    this.headerService.closeMyAccount();
  }

  // public popinConf = new MatDialogConfig;
  // async openJobDeposit() {
  //   const { PopinJobDepositComponent } = await import('src/app/components/popin/popin-jobDeposit/popin-jobDeposit.component');

  //   this.popinConf = {
  //     id: 'popin-jobDeposit',
  //     width: '90vw',
  //     maxWidth: '800px',
  //     panelClass: 'popin-jobDeposit-panel',
  //   };

  //   this.popin.open(PopinJobDepositComponent, this.popinConf);
  // }

}
