import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AtsNavService } from 'src/app/core/services/ats-nav.service';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'ats-nav',
  templateUrl: './nav-mobile.component.html',
  styleUrls: ['./nav-mobile.component.scss'],
  host: {
    class: 'ats-nav'
  },
  encapsulation: ViewEncapsulation.None,
})
export class AtsNavMobileComponent implements OnInit {
  navSub$: Subscription;
  navLinks: Array<any>;
  poolNavRoute$;
  linkActive
  expand: boolean = false;

  constructor(private navService: AtsNavService, public headerService: HeaderService) {

  }

  ngOnInit() {

  }

  displayAdmin = false;
  ngAfterViewInit() {
    this.navSub$ = this.navService.links$.pipe(delay(0)).subscribe(result => {
      if (result) {
        this.navLinks = result;
        this.expand = this.navLinks[0].expand;


        this.navLinks.map(el => {
          if (el.label === 'Administration') {
            this.displayAdmin = true;

            return
          } else {
            this.displayAdmin = false;
          }
        })
      }
    });

  }

  ngOnDestroy() {
    this.navSub$.unsubscribe();
  }

  handleNav(item) {
    this.headerService.closeMyAccount()

    if (item.route.length === 0) {
      this.navService.resetNav(item);
    } else {
      this.navService.restoreNav(item.label);
    }
  }

}
