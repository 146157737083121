<mat-toolbar>
  <div class="ats-nav__wrapper" fxLayout [fxLayoutAlign]="displayAdmin  ? 'flex-start center'  : 'center center'">

    <ul class="ats-nav__list" [class.expand]="expand">
      <ng-container *ngFor="let link of navLinks">
        <li class="ats-nav__list-item" [ngClass]="link.disabled ? 'disabled' : ''" fxLayout="column"
          fxLayoutAlign="center center" fxFlex *ngIf="link.route" #tooltip="matTooltip"
          [matTooltip]="link.disabled ? (link.disabledInfo ? link.disabledInfo : 'La fonctionnalité arrive bientôt') : ''"
          matTooltipPosition="above" (click)="link.disabled ? tooltip.toggle() : ''">

          <!-- For PREVIOUS NAV ICON -->
          <a mat-button (click)="handleNav(link)" *ngIf="link.route.length == 0 && link.label !== 'Mon compte'">
            <mat-icon>{{link.icon}}</mat-icon><br>
            <span [innerHTML]="link.label"></span>
          </a>

          <!-- CLASSIC LINK -->
          <a mat-button [routerLink]="link.route" #rla routerLinkActive="active" (click)="handleNav(link)"
            *ngIf="link.route.length > 0 && link.routerLink">
            <mat-icon *ngIf="!link.svgIcon">{{link.icon}}</mat-icon>
            <mat-icon *ngIf="link.svgIcon" [svgIcon]="link.icon"></mat-icon>
            <br>
            <span [innerHTML]=" link.label"></span>
          </a>

          <!-- HREF LINK -->
          <a mat-button [href]="link.route" #rla routerLinkActive="active" (click)="handleNav(link)"
            *ngIf="link.route.length > 0 && !link.routerLink">
            <mat-icon *ngIf="!link.svgIcon">{{link.icon}}</mat-icon>
            <mat-icon *ngIf="link.svgIcon" [svgIcon]="link.icon"></mat-icon>
            <br>
            <span [innerHTML]="link.label"></span>
          </a>

          <!-- MY ACCOUNT CTA -->
          <a mat-button (click)="headerService.toggleMyAccount()" *ngIf="link.label == 'Mon compte'">
            <mat-icon>{{link.icon}}</mat-icon><br>
            <span [innerHTML]="link.label"></span>
          </a>

        </li>

      </ng-container>
    </ul>

  </div>

</mat-toolbar>