import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthMSALService } from '../services/auth-msal.service';
import { take } from 'rxjs/operators';
import { HeaderService } from '../services/header.service';
import { Subject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { tokenRequest } from 'src/app/auth.config.ts';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  startUrl;
  constructor(public auth: AuthMSALService, public header: HeaderService, public router: Router,
    private msal: MsalService, private userService: UserService, private actRoute: ActivatedRoute) {
    /*this.auth.getIsAuthorized().pipe(take(1)).subscribe(
      (isAuthorized: boolean) => {
        console.log('constructor');
        this.isLogged = isAuthorized;
      }
    );*/

    /*  if (!(<any>window).location.pathname.includes('callback')) {
       (<any>sessionStorage).setItem('initUrl', (<any>window).location.pathname.substr(1));
     } */

  }

  accessToken;
  jwt;
  getJWT() {
    this.jwt = (<any>localStorage).getItem('token')
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let msalInstance = this.msal.instance.getActiveAccount();

    this.getJWT();


    if (msalInstance === null || !this.jwt || this.userService.getTenantsList() === null) {

      // Get external url and store it to redirect after login
      this.startUrl = <any>window.location.href;

      if (this.startUrl !== '/' && !this.startUrl.includes('mon-compte') && !this.startUrl.includes('404')) {
        (<any>sessionStorage).setItem('redirectUrl', JSON.stringify(this.startUrl));
      }

      this.router.navigate(['/mon-compte/callback'])
      return false

    } else if (msalInstance.idTokenClaims['userId'] === '') {
      this.router.navigate(['/mon-compte/acces-refuse'])

    } else if ('sourceUrl' in msalInstance?.idTokenClaims) {
      (<any>sessionStorage).setItem('sourceUrl', msalInstance.idTokenClaims['sourceUrl']);

    }

    /* else {
      console.log('Logout...')
      this.auth.logout();
    } */

    return true;
  }
}