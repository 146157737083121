import { Component, ViewEncapsulation, Renderer2, ElementRef, Inject, ViewChild, NgZone, OnInit } from '@angular/core';
import { environment } from '../environments/environment'
import { BreakpointService } from './core/services/breakpoints.service';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription, Subject, take, shareReplay } from 'rxjs';
import { NgxFaviconService } from './lib/ngx-favicon/ngx-favicon.service';
import { customFavicons, CustomFavicon } from 'src/favicon.config';
import { CookieService } from 'ngx-cookie-service';
import { ScriptLoaderService } from './core/services/scriptloader.service';
import { HeaderService } from './core/services/header.service';
import { UserService } from './core/services/user.service';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RouterNavService } from './core/services/router-nav.service';
import { MatDialog } from '@angular/material/dialog';
import { PopinSubscriptionComponent } from './components/popin/popin-subscription/popin-subscription.component';
import { AuthMSALService } from './core/services/auth-msal.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app',
  templateUrl: './app-jobology.component.html',
  styleUrls: ['./app-jobology.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  isMobile: boolean;
  showSecondTheme: boolean;
  env = environment;
  title = this.env.GlobalVars.name;

  routerSub: Subscription = new Subscription;

  public CustomFavicon: typeof CustomFavicon = CustomFavicon;
  faviconBrand = 'favicon' + this.env.GlobalVars.brand;

  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(DOCUMENT) private document: Document, private metaTitle: Title, private meta: Meta, public el: ElementRef,
    private renderer: Renderer2, public breakpoints: BreakpointService, private userService: UserService,
    private ngxFaviconService: NgxFaviconService<CustomFavicon>, private cookie: CookieService, private script: ScriptLoaderService,
    private zone: NgZone, public headerService: HeaderService, private router: Router, private actRoute: ActivatedRoute,
    private dialog: MatDialog, private auth: AuthMSALService, private snack: MatSnackBar) {
    this.breakpoints.isMobile
      .subscribe((state) => {
        this.isMobile = state.matches;

        !this.isMobile ? this.renderer.addClass(this.el.nativeElement.parentElement, 'isDesktop') : this.renderer.removeClass(this.el.nativeElement.parentElement, 'isDesktop');
      });

    // Set FAVICON
    this.ngxFaviconService.setFaviconByUrl(`${this.env.path.img}/${customFavicons[this.faviconBrand]}`);

    // Theme Color
    this.meta.updateTag({ name: 'theme-color', content: this.env.GlobalVars.primaryColor });

    this.setManifest();

    this.handleExternalUrl();

  }

  accountDatas
  pwaIsActive = false;
  link
  ngOnInit() {
    // console.log('WINDOW LOCATION', (<any>window).location.href.includes('achat'), 'href', (<any>window).location.href)
    if ((<any>window).location.href.includes('achat')) {
      (<any>sessionStorage).setItem('achat', 'true');
    }

    // DETECT PWA MODE
    this.pwaIsActive = (<any>window).matchMedia('(display-mode: standalone)').matches;

    this.redirectToTenant();

    this.zone.runOutsideAngular(() => {
      this.script.initGTM();
      this.script.initSkalin(this.auth.getClaims('email'));
    });

    this.setMetas();
    this.renderer.addClass(this.el.nativeElement, this.env.GlobalVars.name);

    const body = this.el.nativeElement.parentElement;
    this.renderer.addClass(body, 'rt-theme');

  }

  redirectToTenant() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let url = event.url;

        if (url.includes('tenantid')) {
          let tenantid = url.split('tenantid=')[1].split('&achat')[0];
          // Reset tenantslist
          //this.userService.setTenant({ id: url.split('tenantid=')[1] })

          this.getSubscription({ id: tenantid }, true);
        } else {
          this.getTenantActive();

        }
      }
    })
  }

  tenantActive
  tenantActive$;
  alreadyInit = false;
  getTenantActive() {
    this.userService.tenantActive.pipe(take(1)).subscribe(result => {
      if (Object.keys(result)?.length > 0) {
        this.tenantActive = result;

        //if (!this.alreadyInit) {
        this.getSubscription(this.tenantActive);
        //   this.alreadyInit = true;
        // }

      }
    })
  }

  subscription;
  timeRemaining
  getSubscription(tenantActive, redirect?) {
    this.userService.getSubscription(tenantActive?.id).pipe(take(1)).subscribe(result => {
      if (result) {
        this.subscription = result;

        // EXCEPTION FOR CSM - Gestionnaire JOBOLOGY
        if ((<any>localStorage).getItem('applyCSM')) {
          this.userService.listenSubscriptionOn$.next(true);
          this.userService.shareSubscriptionDatas$.next(this.subscription);
          (<any>localStorage).setItem('subscriptionOn', 'true');
          return false
        }

        // Count days remaining
        // let dateFin = this.subscription.dateFinTrial ? Date.parse(this.subscription.dateFinTrial) : Date.parse(this.subscription.dateFin)
        let dateFin = Date.parse(this.subscription.dateFin)
        let dateToday = Date.now();
        // Convert ms to days
        this.timeRemaining = Math.floor((dateFin - dateToday) / (24 * 60 * 60 * 1000));

        // Subscription ends
        // if (this.timeRemaining <= 0 && !this.subscription.renouvellementAuto || !this.subscription.estActif) {
        if (!this.subscription.estActif) {
          this.userService.listenSubscriptionOn$.next(false);
          this.userService.shareSubscriptionDatas$.next(this.subscription);
          (<any>localStorage).setItem('subscriptionOn', 'false')
          this.openPopinSubscription(this.subscription, tenantActive);
          this.userService.removeTenantActive();

        } else {
          this.userService.listenSubscriptionOn$.next(true);
          this.userService.shareSubscriptionDatas$.next(this.subscription);
          (<any>localStorage).setItem('subscriptionOn', 'true');

        }
      } else {

        // EXCEPTION FOR CSM - Gestionnaire JOBOLOGY
        if ((<any>localStorage).getItem('applyCSM')) {
          this.userService.listenSubscriptionOn$.next(true);
          this.userService.shareSubscriptionDatas$.next(this.subscription);
          (<any>localStorage).setItem('subscriptionOn', 'true');
          return false
        }

        this.userService.listenSubscriptionOn$.next(false);
        (<any>localStorage).setItem('subscriptionOn', 'false');
        this.openPopinSubscription(null, tenantActive)

        this.userService.removeTenantActive();
      }

    }, err => {
      this.snack.open(`[${err.status}] Une erreur est survenue. Vous n'avez pas pu vous connecter à la société.`, 'OK', { duration: 0, panelClass: 'error' })
    })
    //}
    //   }
    // })

  }
  openPopinSubscription(tenantSub, tenant) {

    let popin = this.dialog.open(PopinSubscriptionComponent, {
      width: '100%',
      maxWidth: '700px',
      disableClose: false,
      panelClass: 'popin-subscription-panel',
      autoFocus: false,
      data: {
        tenantSub: tenantSub,
        tenant: tenant,
        title: `Renouvelez votre abonnement`,
        content: `<p>Votre abonnement a expiré, ce qui signifie que vous n'avez plus accès à l'application.</p>
        <p>Pour continuer à profiter pleinement de notre application et de ses fonctionnalités avancées, nous vous invitons à
          renouveler votre abonnement dès maintenant.</p>
        <p>Pour consulter nos tarifs d'abonnement et choisir celui qui vous convient le mieux, veuillez cliquer
          sur le lien ci-dessous :
        </p>`,
        displayBack: true,
        linkCTA: `${environment.path.blog.mainUrl}/mon-compte/subscriptions/?tenantid=${tenant.id}`
      }
    })
  }

  handleExternalUrl() {
    const tenantsList = this.userService.getTenantsList();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let tenantId = this.actRoute.snapshot.queryParams['tid'];
        let url = event.url.split('?')[0];

        if (tenantId) {
          // If already connected and tenants list exists
          if (tenantsList) {
            tenantsList.map(tenant => {
              if (tenantId === tenant.id) {
                this.userService.setTenant(tenant);

                setTimeout(() => {
                  this.router.navigate([url]);
                }, 200);
              }
            })
          } else { // Not already connected

          }

        }
      }
    });
  }

  setManifest() {
    this.document.querySelector('#manifest').setAttribute('href', `/manifests/manifest-${this.env.GlobalVars.brand}.webmanifest`);

    // Apple touch icon
    this.document.querySelector('#appleIcon').setAttribute('href', `${environment.path.img}/icons/apple-touch-icon.png`);
  }

  setMetas() {
    this.metaTitle.setTitle(this.env.DK.metaTitleR);
    this.meta.updateTag({ name: 'description', content: this.env.DK.metaDescriptionR });
  }


  // @ViewChild('cookiesBarre', { static: true }) cookiesBarre;
  // showCookiesBarre: boolean = false;

  // cookieConsent = this.cookie.get('displayCookieConsent') === 'y' || !this.env.production ? this.showCookiesBarre = false : this.showCookiesBarre = true;

  // removeCookiesBarre(event) {
  //   event.stopPropagation();
  //   this.showCookiesBarre = false;
  //   this.cookie.set('displayCookieConsent', 'y');

  // }

  closeSidenav = new Subject<boolean>();
  dismissSidenav() {
    this.closeSidenav.next(true);
  }

  public setDefaultFavicon(): void {
    this.ngxFaviconService.setDefaultFavicon();
  }

  public setCustomFavicon(faviconName: CustomFavicon): void {
    this.ngxFaviconService.setCustomFavicon(faviconName);
  }

  public setFaviconByUrl(faviconUrl: string): void {
    this.ngxFaviconService.setFaviconByUrl(faviconUrl);
  }

  ngOnDestroy(): void {
    // this.subscription$.unsubscribe();
    // this.router$.unsubscribe();
    /* this._destroying$.next(undefined);
    this._destroying$.complete(); */
    // this.tenantActive$.unsubscribe()
  }
}
