import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, Output, SimpleChange, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect, MAT_SELECT_CONFIG } from '@angular/material/select';
import { EventEmitter } from '@angular/core';
import { forkJoin, fromEvent, Observable } from 'rxjs';
import { debounceTime, take, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { MatAutocompleteTrigger, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { ReferentielService } from 'src/app/core/services/referentiel.service';
import * as clone from 'clone';

@Component({
  selector: 'auto-complete-location',
  templateUrl: './auto-complete-location.component.html',
  styleUrls: ['./auto-complete-location.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    /*  {
       provide: MAT_SELECT_CONFIG,
       useValue: { overlayPanelClass: 'autocomplete-location-overlay__select' }
     }, */
    /*  {
       provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
       useValue: { overlayPanelClass: 'autocomplete-location-overlay__city' }
     }, */
  ],
})
export class AutoCompleteLocationComponent implements OnInit {
  public countriesList: Array<any> = [{
    name: 'France',
    flagUrl: 'https://flagcdn.com/16x12/fr.png',
    id: 3017382,
    latitude: '',
    longitude: '',
    countryCode: 'FR',
    __typename: ''
  }];

  countrySelected = this.countriesList[0];
  countriesForm = new UntypedFormControl(this.countrySelected);
  radSelected;
  radForm = new UntypedFormControl();
  locationForm = new UntypedFormControl();
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() isReadOnly: boolean;
  @Input() displayCountry: boolean;
  @Input() rad; // Display rayon
  @Input() horizontalDisplay: boolean;
  @Input() locationId;
  @Input() locationName;
  @Input() labelSize: string;
  @Input() labelIcon: boolean;
  @Input() inputIcon: boolean;
  @Input() appearance: boolean;
  @Input() countryCode: boolean;
  @Input() label: string;
  @Input() locationTypes: Array<string>;
  @Input() selectCountryWidth: Array<string>;
  @Output() locationSelected = new EventEmitter();
  @Output() locationChange = new EventEmitter();
  @Output() radChangeEvent = new EventEmitter();
  @Output() clearLocation = new EventEmitter();
  inputFocus = false;

  constructor(@Inject(DOCUMENT) private document: Document, private api: ApiService,
    private actRoute: ActivatedRoute, private referentiel: ReferentielService) {

    /*  this.actRoute.queryParams.subscribe(queryParams => {
       console.log('QUERY', queryParams)
 
       this.locationId = queryParams.localiteId;
 
 
       console.log(queryParams.localite)
       this.locationName = queryParams.localite;
 
     }) */
  }

  ngOnInit(): void {

    if (this.locationName) {
      this.locationForm.setValue(this.locationName);
    }

    // Display Input Icon by default
    this.inputIcon = this.inputIcon === undefined ? true : this.inputIcon;
  }

  ngAfterViewInit() {
    this.autoComplete(null);

    if (!this.locationName) {
      this.initQuery();
    }
  }

  clearInput(event) {
    this.clearLocation.emit(event)
  }

  ngOnChanges(changes) {
    if (changes.locationId) {
      this.locationId = changes.locationId.currentValue
    }

    if (changes.locationName) {
      this.locationName = changes.locationName.currentValue

      // RESET INPUT IF LOCATION NAME IS EMPTY ON CHANGES
      if (this.input && changes.locationName.currentValue === '' || this.input && changes.locationName.currentValue === null) {
        this.input.nativeElement.value = this.locationName;
      }
    }

    // RAYON
    if (changes.rad && this.rad?.values?.length > 0) {
      this.radSelected = this.rad?.values?.length > 0 ? this.rad?.values[0] : null;

      this.rad.values.map(el => {
        if (el.selected) {
          this.radSelected = el;
          return;
        }
      })

      this.radForm.setValue(this.radSelected);
    }
  }

  @ViewChild('input', { static: false, read: MatAutocompleteTrigger }) inputTrigger: MatAutocompleteTrigger;
  @HostListener('click', ['$event.target'])
  handleClick(element) {
    if (this.isReadOnly) {
      return false;
    }

    if (element.tagName !== 'INPUT' && !element.closest('.mat-select')) {
      this.inputTrigger.closePanel();

      // RESET FIELD IF CLICK OUTSIDE AND NOT CITY SELECTED
      // if (this.locationId === null || this.locationId === undefined) {
      //   this.input.nativeElement.value = '';
      // }
    } else if (element.closest('.mat-select')) { // If click on country select list, close city autocompletion panel
      this.inputTrigger.closePanel();
    } else {
      this.inputTrigger.openPanel();
    }
  }

  isFirstClick: boolean = true;
  @ViewChild('select', { static: false }) select: MatSelect;
  currentCountryId;
  getCountries() {
    // this.api.countries().valueChanges.pipe(take(1)).subscribe(result => {
    this.referentiel.countries().pipe(take(1)).subscribe(result => {
      let datas = clone(result);
      this.countriesList = datas;

      // On first click, focus FRANCE in the list
      if (this.isFirstClick) {
        this.isFirstClick = false;
        let franceIndex;
        let optionId;
        this.countriesList.map((el, i) => {

          if (el.name === 'France') {
            franceIndex = i;
            this.countriesForm.setValue(this.countriesList[franceIndex])

            return false;
          }
        })

        optionId = `option-${this.countriesList[franceIndex].countryCode}`;

        setTimeout(() => {
          this.document.getElementById(optionId).scrollIntoView();
        }, 200)
      }

      this.currentCountryId = this.countriesForm.value.id
    });
  }

  countryChange(event) {
    // Reset input value if country has changed
    if (event.value.id !== this.currentCountryId) {
      this.currentCountryId = event.value.id;
      this.locationsList = [];
      this.input.nativeElement.value = '';
    }

  }

  @ViewChild('selectRad') selectRad;
  radChange(event) {
    // Reset input value if rad has changed
    //if (event.value.id !== this.radSelected.id) {
    this.currentCountryId = event.value.id;

    this.radChangeEvent.emit(event);

    // }
  }


  /* autoComplete(event) {
    const value = event.target.value;
    const countryCode = this.select.value.countryCode;

    this.api.locationAutocomplete(value, countryCode).valueChanges.pipe(take(1)).subscribe(result => {
      this.locationsList = result.data.locationAutocomplete;
    })

  } */

  @ViewChild('input', { static: false }) input: ElementRef;
  locationsList = [];
  request = [];
  fieldUpdated = false;
  autoComplete(event) {
    // this.locationsList = [];


    // Reset array if too many requests
    /*if (this.request.length > 2) {
      this.request.shift();
    }*/

    // Chain multiples requests and receive results in the right order
    /* this.request.push(this.api.locationAutocomplete(value, countryCode).valueChanges.pipe(take(1)));

    const requests = forkJoin<Array<any>>(this.request);

    requests.subscribe(result => {
      this.locationsList = result;
    });*/

    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(result => {
        let key = result.key;

        if (result.key === "ArrowUp" || result.key === "ArrowDown") {
          return false;
        }

        if (result.key === 'End') {
          this.input.nativeElement.selectionStart = this.input?.nativeElement.value.length;
        }

        if (result.key === 'Home') {
          this.input.nativeElement.selectionStart = 0;
          this.input.nativeElement.selectionEnd = 0;
        }

        let value = this.input.nativeElement.value;
        let countryCode = this.displayCountry ? this.select.value.countryCode : 'FR';
        this.fieldUpdated = true;

        this.optionSelected = undefined;

        this.locationChange.emit({ result, value });

        // this.radChangeEvent.emit(null);

        // this.api.locationAutocomplete(value, countryCode, 10, this.locationTypes).valueChanges.pipe(take(1)).subscribe(result => {

        //   if (result.data.locationAutocomplete && result.data.locationAutocomplete.length) {
        //     this.locationsList.push(result);
        //   }
        // });

        this.referentiel.locationAutocomplete(value, countryCode, 10, this.locationTypes).pipe(take(1)).subscribe(result => {
          let datas = clone(result);
          if (datas && datas.length) {
            this.locationsList = datas;

            // Reset RAD
            if (this.rad && key !== 'Enter') {
              this.rad = null;
              this.radChangeEvent.emit(null);
            }
          }
        });
      })

    fromEvent<KeyboardEvent>(this.input.nativeElement, 'paste')
      .pipe(
        debounceTime(150),
        distinctUntilChanged()
      )
      .subscribe(result => {
        let key = result.key;

        if (result.key === 'End') {
          this.input.nativeElement.selectionStart = this.input?.nativeElement.value.length;
        }

        if (result.key === 'Home') {
          this.input.nativeElement.selectionStart = 0;
          this.input.nativeElement.selectionEnd = 0;
        }

        let value = this.input.nativeElement.value;
        let countryCode = this.displayCountry ? this.select.value.countryCode : 'FR';
        this.fieldUpdated = true;

        this.optionSelected = undefined;

        this.locationChange.emit({ result, value });

        // this.radChangeEvent.emit(null);

        // this.api.locationAutocomplete(value, countryCode, 10, this.locationTypes).valueChanges.pipe(take(1)).subscribe(result => {

        //   if (result.data.locationAutocomplete && result.data.locationAutocomplete.length) {
        //     this.locationsList.push(result);
        //   }
        // });
        this.referentiel.locationAutocomplete(value, countryCode, 10, this.locationTypes).pipe(take(1)).subscribe(result => {
          let datas = clone(result);
          if (datas && datas.length) {
            this.locationsList = datas;

            // Reset RAD
            if (this.rad && key !== 'Enter') {
              this.rad = null;
              this.radChangeEvent.emit(null);
            }

          }
        });
      })
  }

  optionSelected;
  autoCompleteOptionSelected(event, ev?) {
    this.optionSelected = event;
    this.locationForm.setErrors(null);

    // Reset RAD
    if (this.rad) {
      this.rad = null;
      this.radChangeEvent.emit(null);
    }

    this.locationSelected.emit(event);
    this.locationChange.emit(event);
  }

  setControls(isAlreadyInit?) {
    let datas;

    if (this.input.nativeElement.value === '') { // If empty error
      this.locationsList.length ? this.locationForm.setErrors({ incorrectFormat: true }) : this.locationForm.setErrors({ required: true });

      return false
    } else if (this.input.nativeElement.value && isAlreadyInit && !this.fieldUpdated) { // If already init and no update in the field
      return true;
    } else {
      this.locationForm.setErrors({ incorrectFormat: true })
      return false

    }
  }

  // setControls(isAlreadyInit?) {
  //    let datas;
  //   // If list is loaded but user didn't select an option in the list, auto select first element
  //   if (this.input.nativeElement.value
  //     && this.locationsList[0]
  //     && this.locationsList[0].data.locationAutocomplete.length) {

  //     if (this.optionSelected === undefined && this.fieldUpdated) { // If list is loaded and available
  //       this.locationForm.setValue(this.locationsList[this.locationsList.length - 1].data.locationAutocomplete[0].displayName)

  //       this.locationId = this.locationsList[0].data.locationAutocomplete[0].id;
  //       this.locationName = this.locationsList[0].data.locationAutocomplete[0].displayName;


  //     } else { // If user has already selected an option in the list
  //       this.locationId = this.optionSelected ? this.optionSelected.id : this.locationId;
  //       this.locationName = this.optionSelected ? this.optionSelected.value : this.locationName;
  //     }

  //     this.locationForm.setErrors(null);
  //     this.fieldUpdated = false;

  //     return true

  //   } else if (this.input.nativeElement.value === '') { // If empty error
  //     this.locationsList.length ? this.locationForm.setErrors({ incorrectFormat: true }) : this.locationForm.setErrors({ required: true });

  //     return false
  //   } else if (this.input.nativeElement.value && isAlreadyInit && !this.fieldUpdated) { // If already init and no update in the field
  //     return true;
  //   } else {
  //     this.locationForm.setErrors({ incorrectFormat: true })
  //     return false

  //   }
  // }

  initQuery() {
    let countryCode = this.displayCountry ? this.select.value.countryCode : 'FR';

    this.referentiel.locationAutocomplete('', countryCode, 10, this.locationTypes).pipe(take(1)).subscribe(result => {
      let datas = clone(result);
      if (datas && datas.length) {
        this.locationsList = datas;
      }
    });
  }
}
