import { Component, ElementRef, HostListener, NgZone, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { ScriptLoaderService } from 'src/app/core/services/scriptloader.service';
import { UserService } from 'src/app/core/services/user.service';
import { PopinWelcomeComponent } from './popin-welcome/popin-welcome.component';
import * as clone from 'clone';
import { environment } from '@env';


const MOBILE_KEY = makeStateKey('isMobile');
@Component({
  selector: 'ats',
  templateUrl: './ats.component.html',
  styleUrls: ['./ats.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ats'
  }
})
export class AtsComponent implements OnInit {
  isMobile;
  stateKey;
  params;
  env = environment;

  logoUrl = this.env.path.img + '/svg/logo.svg#logo'

  constructor(private breakpoint: BreakpointService, private state: TransferState, private render: Renderer2, private el: ElementRef,
    private script: ScriptLoaderService, private zone: NgZone, private auth: AuthMSALService, private actRoute: ActivatedRoute,
    private popin: MatDialog, private userService: UserService, private router: Router) {
    this.breakpoint.isMobile.subscribe(state => {
      this.isMobile = state.matches;
    });

  }

  idSurvey = {
    prod: 'hYXFT7HUykW9iaE4muZ_mpkpczTmTfVErdni8JsHt7hUQjRLN0xHOVQ5Q05CSEw2U0w3VURPMTE1US4u',
    preprod: 'hYXFT7HUykW9iaE4muZ_mpkpczTmTfVErdni8JsHt7hURTZBU0tBWjIxOVRWRlNQTDQ0UFBPQVM4Si4u'
  }

  userDatas;
  displayAdmin = false;
  ngOnInit(): void {
    this.userDatas = this.auth.getClaims();

    // If no Active Tenant, redirect to Tenants List
    if ((<any>localStorage).getItem('tenantActive') === null) {
      // No active tenant selected
      this.router.navigate(['/mon-compte/tenant']);
    }

    // If New User, open welcome message
    // if ((<any>sessionStorage).getItem('newUser') && (<any>sessionStorage).getItem('newUser') !== 'false') {
    if (this.auth.getClaims('newUser') && (<any>localStorage).getItem('newUser') !== 'false') {
      this.userService.getRecruteur(this.userDatas.sub).pipe(take(1)).subscribe(result => {
        let recruteurResult = clone(result);

        if (!recruteurResult.avatarBase64 && this.userDatas.newUser) {
          this.openPopinWelcome(this.userDatas);
        }
      })
    }

    this.render.addClass(this.el.nativeElement.parentElement.parentElement, 'ats-page');

    if (!(<any>window).SurveyEmbed) {
      this.zone.runOutsideAngular(() => {
        this.script.initCustomerVoice(this.idSurvey);
      })
    }

    // Handle Administration Menu
    this.userService.getTenantsAutocomplete('authorization', null, true).pipe(take(1)).subscribe(result => {
      if (result) {
        this.displayAdmin = true;
        this.userService.getTenantsAutocompleteAuthorization$.next({ displayAdmin: true })
      }
    }, err => {
      console.log(err)
    })

    // this.script.renderCustomerVoice();

    /*  this.stateKey = this.state.get(MOBILE_KEY, <any>{});
 
     //console.log('STATEKEY', this.stateKey)
 
     if (Object.keys(this.stateKey).length === 0) {
       this.breakpoint.isMobile.subscribe(state => {
         this.isMobile = state.matches;
 
         this.toto = {
           isMobile: this.isMobile
         }
         this.state.set(MOBILE_KEY, this.toto);
 
         // console.log('GEEEEEEET', this.state.get(MOBILE_KEY, <any>{}))
       });
     } else {
       this.isMobile = this.stateKey
     }
  */
  }

  @ViewChild('atsSidenav', { static: false }) public sidenav: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.isMobile) {
      return false
    }

    // Handle fixed sidenav on Desktop
    if ((<any>window).pageYOffset < 70) {
      this.render.removeClass(this.sidenav.nativeElement, 'scroll')
    } else {
      this.render.addClass(this.sidenav.nativeElement, 'scroll')
    }
  }

  openPopinWelcome(userDatas) {
    const popin = this.popin.open(PopinWelcomeComponent, {
      id: 'popin-welcome',
      panelClass: ['popin-welcome-panel'],
      width: this.isMobile ? '100%' : '600px',
      maxWidth: this.isMobile ? '600px' : 'auto',
      data: userDatas,
      autoFocus: false,
    })
  }

  ngOnDestroy() {
    // this.state.set(MOBILE_KEY, {});
  }

}
