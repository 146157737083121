import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'dragdrop-files',
  templateUrl: './dragdrop-files.component.html',
  styleUrls: ['./dragdrop-files.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DragDropFilesComponent implements OnInit {
  @ViewChild("fileDropRef", { static: true }) fileDropEl: ElementRef;
  @Input() src;
  @Input() class;
  @Input() imgThumbnail = true;
  @Input() acceptType;
  @Input() displayCTA = false;
  @Input() descriptionCTA = `Cliquez sur "Choisir un fichier" pour charger votre CV.<br>
  Extensions de fichier acceptées : .jpg, .jpeg, .png, .doc, .docx, .pdf, .txt, .rtf, .odt`;
  @Input() displayLabelDescription = true;
  @Input() labelWidth = this.imgThumbnail ? 50 : 100;
  @Input() formatOutputFile = false;
  @Input() isPicture = false;
  @Input() srcBase64 = true;
  @Input() fxLayout;
  @Input() thumbnailRectanglePortrait = false;
  @Input() customPlaceholder = false;

  sizeLimit = 5;
  acceptValue;
  classComponent;

  files: any[] = [
    {
      url: environment.path.img + '/svg/upload-file.svg',

    }
  ];

  imageSrc;

  documentTypes = ['application/pdf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword', 'text/plain']

  allTypes = ['image/png', 'image/jpeg', 'application/pdf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword', 'text/plain']


  constructor() {

  }

  ngOnInit(): void {
    // this.imageSrc = this.files[0].url;
    if (this.acceptType === 'documents') {
      this.acceptValue = '.pdf,.xls,.xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    } else if (this.acceptType === 'all') {
      this.acceptValue = 'image/png, image/jpeg, .pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain'
    } else if (this.acceptType === 'videos') {
      this.acceptValue = "video/*"
    } else {
      this.acceptValue = 'image/*'
    }
    // Change placeholder img if is avatar/picture
    if (this.isPicture) {
      this.files[0].url = this.thumbnailRectanglePortrait ? environment.path.img + '/svg/upload-file-photo_rectangle-portrait.svg' : environment.path.img + '/svg/upload-file-photo.svg'
    } else if (this.acceptType === 'videos') {
      this.files[0].url = this.thumbnailRectanglePortrait ? environment.path.img + '/svg/upload-file-video_large.svg' : environment.path.img + '/svg/upload-file-video.svg'
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.src != null && this.srcBase64) {
        if (!this.src.includes('base64') && !this.src.includes('directus.media')) {
          this.imageSrc = `data:image/jpeg;base64,${this.src}`;
        } else {
          this.imageSrc = this.src;

        }
      } else if (this.src != null) {
        this.imageSrc = this.src;
      }

      this.classComponent = this.class !== undefined ? this.class : '';

    }, 100);
  }

  formatFile(img) {
    if (!img) {
      return null
    }

    let logoFormat;

    if (img.includes('jpeg')) {
      logoFormat = img.replace('data:image/jpeg;base64,', '');
    } else if (img.includes('png')) {
      logoFormat = img.replace('data:image/png;base64,', '');
    }

    return logoFormat;
  }


  removeFile(file, type?) {
    this.files.map((el, index) => {
      if (el.url === file.url) {
        this.files.splice(index, 1);
      }
    })

    if (this.files.length === 0) {
      // this.imageSrc = `data:image/jpeg;base64,${this.src}`;
      this.imageSrc = `${environment.path.img}/svg/upload-file.svg`;
      if (this.isPicture) {
        //this.imageSrc = `${environment.path.img}/svg/upload-file-photo.svg`;

        if (this.customPlaceholder) {
          this.imageSrc = null;
        } else {
          this.imageSrc = this.thumbnailRectanglePortrait ? environment.path.img + '/svg/upload-file-photo_rectangle-portrait.svg' : environment.path.img + '/svg/upload-file-photo.svg'
        }

      } else if (this.acceptType === 'videos') {
        this.files[0].url = this.thumbnailRectanglePortrait ? environment.path.img + '/svg/upload-file-video_large.svg' : environment.path.img + '/svg/upload-file-video.svg'
      }

      this.src = null;

    } else {
      this.imageSrc = this.files[0].url;
    }

    if (!this.isErrorFileSize) {
      this.fileDropped.emit(this.files[0]);
      this.rawFileDropped.emit(this.files[0]);
    }
  }

  @Output() rawFileDropped = new EventEmitter<any>();
  isErrorFileSize;
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    let fileType = $event[0].type;

    let checkType = false;
    // Check file type
    if (this.acceptType === 'documents') {
      this.documentTypes.map((type => {
        if (fileType === type) {
          checkType = true;
        }
      }))
    }

    if (this.acceptType === 'all') {
      this.allTypes.map((type => {
        if (fileType === type) {
          checkType = true;
        }
      }))
    }

    if (this.acceptType === undefined) {
      checkType = true;
    }

    if (!checkType) {
      this.errorFileDropped.emit('wrongType');
      return false;
    }

    let fileMB = $event[0].size / 1024 ** 2;
    // If exceeds SIZE LIMIT remove file
    if (fileMB >= this.sizeLimit) {
      this.isErrorFileSize = true;
      //this.removeFile(this.files[0]);
      this.errorFileDropped.emit('exceed');
    } else {
      this.isErrorFileSize = false;
      this.rawFileDropped.emit($event[0])
      this.prepareFilesList($event);
    }

  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    let fileType = files[0].type;

    let checkType = false;
    // Check file type
    if (this.acceptType === 'documents') {
      this.documentTypes.map((type => {
        if (fileType === type) {
          checkType = true;
        }
      }))
    }

    if (this.acceptType === 'all') {
      this.allTypes.map((type => {
        if (fileType === type) {
          checkType = true;
        }
      }))
    }

    if (this.acceptType === undefined) {
      checkType = true;
    }

    if (!checkType) {
      this.errorFileDropped.emit('wrongType');
      return false;
    }

    let fileMB = files[0].size / 1024 ** 2;
    // If exceeds SIZE LIMIT remove file
    if (fileMB >= this.sizeLimit) {
      this.isErrorFileSize = true;
      //this.removeFile(this.files[0]);
      this.errorFileDropped.emit('exceed');
    } else {
      this.isErrorFileSize = false;
      this.rawFileDropped.emit(files[0])
      this.prepareFilesList(files);
    }
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  @Output() errorFileDropped = new EventEmitter<any>();
  /**
  * Convert Files list to normal array list
  * @param files (Files List)
  */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      // this.files.push(item);
      this.files[0] = item;
    }

    this.fileDropEl.nativeElement.value = "";
    // this.uploadFilesSimulator(0);

    this.formatBytes(this.files[0].size);

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  @Output() fileDropped = new EventEmitter<any>();
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }

    // Async task to convert to base64 
    const reader = new FileReader();
    reader.readAsDataURL(this.files[0]);
    reader.onload = () => {
      this.files[0].url = reader.result;
      this.imageSrc = reader.result;


      if (this.formatOutputFile) {
        this.fileDropped.emit(this.formatFile(reader.result));
      } else {
        this.fileDropped.emit(reader.result);
      }
    };

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];


  }
}
