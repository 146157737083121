<div class="autocomplete-location" fxLayout fxLayoutAlign="center end"
  [ngClass]="horizontalDisplay ? 'autocomplete-location--horizontal' : ''"
  [class.autocomplete-location--with-rad]="rad">

  <ng-container *ngIf="horizontalDisplay; else verticalDisplay">
    <mat-form-field class="form-field">
      <div fxLayout="column" fxFlex="100">

        <div fxLayout="column" fxLayout.gt-sm="row" fxFlex="100">
          <ng-container *ngIf="labelSize; else classicLabel">
            <label for="input" [fxFlex]="labelSize">
              <strong>{{label ? label : 'Localisation'}}<span *ngIf="required">*</span>
              </strong>
            </label>
          </ng-container>

          <ng-template #classicLabel>
            <label for="input">
              <strong>{{label ? label : 'Localisation'}}<span *ngIf="required">*</span></strong>
            </label>
          </ng-template>

          <mat-icon [ngStyle.lt-md]="{ 'position': 'relative', 'bottom' : '-40px'}">room</mat-icon>

          <input matInput #input class="input" [placeholder]="placeholder" [formControl]="locationForm"
            [required]="required ? required : false" [readonly]="isReadOnly" (blur)="inputFocus = false"
            (focus)="inputFocus = true" *ngIf="isReadOnly">

          <ng-container *ngIf="!isReadOnly">
            <input matInput #input class="input" [placeholder]="placeholder" [formControl]="locationForm"
              [matAutocomplete]="list" [required]="required ? required : false" [readonly]="isReadOnly"
              (blur)="inputFocus = false" (focus)="inputFocus = true">

            <mat-autocomplete #list="matAutocomplete" [class]="['scrollbar', 'grey']"
              (optionSelected)="autoCompleteOptionSelected($event.option)">

              <mat-option *ngFor="let location of locationsList" [value]="location.displayName" [id]="location.id">
                {{location.displayName}}
              </mat-option>

            </mat-autocomplete>

            <div class="autocomplete-location__clear" *ngIf="locationForm.value">
              <button mat-icon-button aria-label="Effacer" (click)="locationForm.setValue(''); clearInput($event);"
                type="button">
                <mat-icon>close</mat-icon>
              </button>
            </div>

          </ng-container>

        </div>

        <mat-error *ngIf="locationForm?.hasError('required')"><span style="font-size: 11px">Le champ est obligatoire.
            Veuillez renseigner une localisation.</span>
        </mat-error>

        <mat-error *ngIf="locationForm?.hasError('selectionList')">La localisation est incorrecte. Veuillez selectionner
          une option dans la liste.
        </mat-error>
        <mat-error *ngIf="locationForm?.hasError('incorrectFormat')">Veuillez renseigner une localisation
          valide.</mat-error>
      </div>
    </mat-form-field>
  </ng-container>

  <ng-template #verticalDisplay>

    <mat-form-field class="form-field" fxFlex [appearance]="appearance">

      <ng-container *ngIf="labelSize; else classicLabel">
        <label for="input" [fxFlex]="labelSize"><strong>{{label ? label : 'Localisation'}} <span
              *ngIf="required">*</span></strong></label>
      </ng-container>

      <ng-template #classicLabel>
        <label for="input">
          <mat-icon class="autocomplete-location__label-icon" *ngIf="labelIcon">room</mat-icon>
          <strong>{{label ? label : 'Localisation'}} <span *ngIf="required">*</span></strong>
        </label>
      </ng-template>

      <mat-icon class="autocomplete-location__input-icon" *ngIf="inputIcon">room</mat-icon>
      <!--    <input matInput #input class="input" (keyup)="autoComplete($event)" [formControl]="locationForm"
        [matAutocomplete]="list" [required]="required ? required : false"> -->
      <input matInput #input class="input" [placeholder]="placeholder" [formControl]="locationForm"
        [required]="required ? required : false" type="text" [readonly]="isReadOnly" (blur)="inputFocus = false"
        (focus)="inputFocus = true" *ngIf="isReadOnly">

      <ng-container *ngIf="!isReadOnly">
        <input matInput #input class="input" [placeholder]="placeholder" [formControl]="locationForm"
          [matAutocomplete]="list" [required]="required ? required : false" type="text" [readonly]="isReadOnly"
          (blur)="inputFocus = false" (focus)="inputFocus = true">

        <mat-autocomplete #list="matAutocomplete" [class]="['scrollbar', 'grey']"
          (optionSelected)="autoCompleteOptionSelected($event.option, $event)">
          <!-- <mat-option *ngFor="let location of locationsList[locationsList.length - 1]?.data?.locationAutocomplete"
            [value]="location.displayName" [id]="location.id"> -->
          <mat-option *ngFor="let location of locationsList" [value]="location.displayName" [id]="location.id">
            {{location.displayName}}
          </mat-option>
        </mat-autocomplete>

        <ng-container *ngIf="rad?.values?.length > 0">
          <div class="autocomplete-location__rad" [title]="rad?.nom"
            style="position: absolute; top: 0; right: 40px; top: 7px;">
            <mat-form-field class="form-field" [fxFlex]="'90px'" matSuffix>
              <!-- <mat-label>Rayon</mat-label> -->
              <mat-select #selectRad [formControl]="radForm" panelClass="autocomplete-location__select scrollbar grey"
                (selectionChange)="radChange($event)" [value]="radSelected">

                <mat-select-trigger fxLayoutAlign="start center">
                  {{radForm.value.libelle}}
                </mat-select-trigger>

                <mat-option [id]="element.id" [value]="element" *ngFor="let element of rad.values">
                  <div fxLayoutAlign="start center" [title]="element.libelle">

                    {{element.libelle}} <em>&nbsp;({{element.count}})</em>
                  </div>
                </mat-option>


              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>


        <div class="autocomplete-location__clear" *ngIf="locationForm.value" [style.top]="!label  ? '29px' : ''">
          <button mat-icon-button aria-label="Effacer" (click)="locationForm.setValue(''); clearInput($event);"
            type="button">
            <mat-icon>close</mat-icon>
          </button>
        </div>

      </ng-container>


      <mat-error *ngIf="locationForm?.hasError('required')">Le champ est obligatoire. Veuillez renseigner une
        localisation.
      </mat-error>

      <mat-error *ngIf="locationForm?.hasError('selectionList')">La localisation est incorrecte. Veuillez selectionner
        une option dans la liste.
      </mat-error>
      <mat-error *ngIf="locationForm?.hasError('incorrectFormat')">Veuillez renseigner une localisation
        valide.</mat-error>
    </mat-form-field>
  </ng-template>

  <ng-container *ngIf="displayCountry">
    <div class="autocomplete-location-country" [title]="countriesForm.value.name">
      <mat-form-field class="form-field" fxFlex="120px"
        [fxFlex.gt-sm]="selectCountryWidth ? selectCountryWidth : '200px'" appearance="outline"
        (click)="getCountries()">
        <mat-label>Pays</mat-label>
        <mat-select #select [formControl]="countriesForm" panelClass="autocomplete-location__select scrollbar grey"
          (selectionChange)="countryChange($event)">

          <mat-select-trigger fxLayoutAlign="start center">
            <img [src]="countriesForm.value.flagUrl" alt="">
            <span fxFlexOffset="5px" *ngIf="!countryCode">{{ countriesForm.value.name }}</span>
            <span fxFlexOffset="5px" *ngIf="countryCode">{{ countriesForm.value.countryCode }}</span>



            <!--div fxLayoutAlign="start center" *ngIf="countriesList?.length > 0">
          <img [src]="countriesForm.value.flagUrl" alt="">
          <div fxFlexOffset="5px">{{ countriesForm.value.name }}</div>
        </div-->

          </mat-select-trigger>

          <mat-option [id]="'option-' + country.countryCode" [value]="country" *ngFor="let country of countriesList">
            <div fxLayoutAlign="start center" [title]="country.name">
              <img [src]="country.flagUrl" alt="" loading="lazy">
              <div fxFlexOffset="5px">
                {{country.name}}
              </div>

            </div>
          </mat-option>


        </mat-select>
      </mat-form-field>
    </div>
  </ng-container>

</div>