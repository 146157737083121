import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { UserService } from './user.service';
import { BreakpointService } from './breakpoints.service';

@Injectable({
  providedIn: 'root'
})
export class AtsNavService {
  public links: Array<object> = [
    //{ label: 'Sourcing', route: ['/postes'], icon: 'work', disabled: false, routerLink: true },
    { label: 'Sourcing', route: ['/sourcing'], icon: 'work', disabled: false, routerLink: true },
    // { label: 'Candidatures <br> spontanées', route: ['/candidatures-spontanees'], icon: 'email', disabled: true, routerLink: true },
    // { label: 'Vivier', route: ['/vivier', 'profils'], icon: 'groups', disabled: false, routerLink: true },
    // { label: 'Marque <br> employeur', route: ['/gerer-mon-portrait-entreprise'], icon: 'web', disabled: true, routerLink: true },
    { label: 'Marque <br> employeur', route: ['/marque-employeur'], icon: 'web', disabled: false, routerLink: true },
    { label: 'Formation<br>au recrutement', route: ['/formation-recrutement'], icon: 'devices', disabled: false, routerLink: true },
    { label: 'Paramètres', route: ['/parametres'], icon: 'settings', disabled: false, routerLink: true },
    // { label: 'Administration', route: ['/administration'], icon: 'domain', disabled: true, routerLink: true },
    { label: 'Mon compte', route: [], icon: 'lock', disabled: false, routerLink: false }
  ];

  public links$: Subject<Array<any>> = new BehaviorSubject<Array<any>>(this.links);

  displayAdmin = false;
  constructor(private userService: UserService, private bk: BreakpointService) {
    this.links$.subscribe(result => {
      if (result) {
        let datas = result;

        // Handle Administration Menu display
        if (datas[0].label === 'Sourcing') {

          this.userService.getTenantsAutocompleteAuthorization$.subscribe(result => {
            if (result?.displayAdmin) {

              this.displayAdmin = true;
              this.links = [
                { label: 'Sourcing', route: ['/sourcing'], icon: 'work', disabled: false, routerLink: true },
                { label: 'Marque <br> employeur', route: ['/marque-employeur'], icon: 'web', disabled: false, routerLink: true },
                { label: 'Formation<br>au recrutement', route: ['/formation-recrutement'], icon: 'devices', disabled: false, routerLink: true },
                { label: 'Paramètres', route: ['/parametres'], icon: 'settings', disabled: false, routerLink: true },
                { label: 'Administration', route: ['/administration'], icon: 'domain', disabled: false, routerLink: true },
                { label: 'Mon compte', route: [], icon: 'lock', disabled: false, routerLink: false }
              ];

            }
          }, err => {
            this.displayAdmin = false;
          })

        }
      }
    })
  }

  public updateNav(newElements: Array<any>, currentPage = {}, expand = false) {

    if (Object.keys(currentPage).length > 0) {
      newElements.unshift(
        { label: '', route: [], icon: 'arrow_back_ios', ...currentPage, savedNav: [newElements] }
      );
    }

    if (expand) {
      newElements.unshift(
        { expand: true }
      );
    }

    this.links$.pipe(delay(0));
    this.links$.next(newElements);
  }

  public resetNav(item) {
    if (item) {
      this.links.push({ name: item.name, savedNav: item.savedNav });
    }

    this.links$.next(this.links);
    this.links$.pipe(delay(0));
  }

  public restoreNav(name) {
    this.links.map(element => {
      if (element['name'] !== undefined && element['name'] === name) {
        this.updateNav(element['savedNav'][0]);
      }
    })
  }
}
