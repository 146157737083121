import { GlobalVars } from './global/jobology_fr';
import { defaultEnvironment } from './environment.defaults'
import DicoKeys from '@dk/jobology_fr.json';

export const environment = {
  //...defaultEnvironment,
  production: false,
  name: 'preprod',
  path: {
    root: `https://app.preprod.${GlobalVars.name}.fr`,
    img: `https://app.preprod.${GlobalVars.name}.fr/assets/images/${GlobalVars.name}`,
    appRoot: defaultEnvironment.path.appRoot,
    auth: 'https://accounts.preprod.jobology.fr',
    api: 'https://api.preprod.jobology.fr/graphql',
    api2: 'https://api.preprod.jobology.fr',
    prod: `https://app.${GlobalVars.name}.fr`,
    blog: {
      url: 'https://www.cache.jobology.fr/wp-json/wp/v2/',
      tagId: 64,
      mainUrl: 'https://preprod.jobology.fr'
    }
  },
  MSAL: {
    clientId: '97d8950b-9b49-486a-95da-a29b2a6a0d3d',
    scope: {
      path: 'jobologypreprod.onmicrosoft.com',
      url: `https://jobologypreprod.onmicrosoft.com/jobology-api/full`
    }
  },
  GlobalVars: GlobalVars,
  DK: DicoKeys,
  gtmID: 'GTM-PD994XW'
};
